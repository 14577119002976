<template>
  <div>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-file"></i> Vistorias Solicitadas
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-table
          bordered
          :fields="columnsTableInspection"
          :items="inspections"
          :busy="load"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>
          <template v-slot:cell(created_at)="data">
            {{ data.item.created_at | moment("DD/MM/YY") }}
          </template>
          <template v-slot:cell(actions)="data">
            <div class="d-flex justify-content-around">
              <b-button
                v-b-tooltip.hover
                size="sm"
                title="Editar vistoria"
                :to="{ name: 'inspection-edit', params: { id: data.item.id } }"
                variant="warning"
              >
                <i class="fas fa-pen"></i>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </b-row>
  </div>
</template>
<script>
import InspectionService from '@/Services/InspectionService';

export default {
  data() {
    return {
      inspections: [],
      load: true,
      columnsTableInspection: InspectionService.getFieldsTableInspections(),
    };
  },
  methods: {
    async getAll() {
      const { data } = await InspectionService.getList();
      this.inspections = data.map((item) => ({
        id: item.id,
        client: item.project.client.name,
        agent: item.project.agent !== null ? item.project.agent.name : '',
        created_at: item.created_at,
        status: item.status.name,
      }));

      this.load = false;
    },
  },
  created() {
    this.getAll();
  },
};
</script>
