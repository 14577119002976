/* eslint-disable class-methods-use-this */
import Service from './Service';

class InspectionService extends Service {
  constructor() {
    super('inspections');
  }

  getFieldsTableInspections() {
    return [
      {
        key: 'client',
        label: 'Cliente',
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
      },
      {
        key: 'agent',
        label: 'Agente',
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
      },
      {
        key: 'created_at',
        label: 'Data',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'status',
        label: 'Status',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'actions',
        label: 'Ações',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
    ];
  }
}

const inspectionService = new InspectionService();
export default inspectionService;
